
import { defineComponent, onMounted } from 'vue'
import ProductUsers from '@/components/ProductUsers.vue'
import SecondFooter from '@/components/SecondFooter.vue'

import { routGo } from '@/hooks/links'
export default defineComponent({
  name: 'Enging',
  components: {
    ProductUsers,
    SecondFooter
  },
  setup(){
    onMounted(() => {
      _hmt.push(['_trackPageview', '/engine'])
    })
    return { routGo }
  }
})
